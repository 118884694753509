/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Helmet } from 'react-helmet';

import { css } from '@emotion/react';

import { Footer } from '../components/Footer';
import SiteNav from '../components/header/SiteNav';
import { PostFullContent } from '../components/PostContent';
import { Wrapper } from '../components/Wrapper';
import IndexLayout from '../layouts';
import {
  inner,
  outer,
  SiteArchiveHeader,
  SiteHeader,
  SiteMain,
  SiteNavMain,
} from '../styles/shared';
import { NoImage, PostFull } from '../templates/post';
import { colors } from '../styles/colors';
import { Link, graphql, StaticQuery } from "gatsby";
import { getSrc } from "gatsby-plugin-image";

interface ProfilePicProps {
  src?: any;
}

function ProfilePic() {
  return (
    <StaticQuery
      query={graphql` query profilePicQuery {
          src: file(relativePath: {eq: "img/me.png"}) {
            childImageSharp {
              gatsbyImageData(quality: 100, width: 512, layout: FIXED)
            }
          }
        }
      `}
      render={(data: ProfilePicProps) => (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <img src={getSrc(data.src)} alt="@ Droidcon Berlin 2023" style={{ width: '69%' }}/>
          <div style={{ textAlign: 'center', fontSize: 'medium', marginTop: '20px' }}>@ Droidcon Berlin 2023</div>
        </div>
      )}
    />
  );
}

const PageTemplate = css`
  .site-main {
    margin-top: 64px;
    padding-bottom: 4vw;
    margin-right: 24px;
    margin-left: 24px;
    background: #fff;
  }

  @media (prefers-color-scheme: dark) {
    .site-main {
      /* background: var(--darkmode); */
      background: ${colors.darkmode};
    }
  }
`;

function About() {
  return (
    <IndexLayout>
      <Helmet>
        <title>About Me</title>
      </Helmet>
      <Wrapper css={PageTemplate}>
        <header className="site-archive-header no-image" css={[SiteHeader, SiteArchiveHeader]}>
          <div css={[outer, SiteNavMain]}>
            <div css={inner}>
              <SiteNav isHome={false}/>
            </div>
          </div>
        </header>
        <main id="site-main" className="site-main" css={[SiteMain, outer]}>
          <div css={inner}>
            <article className="post page" css={[PostFull, NoImage]}>
              <PostFullContent className="post-full-content">
                <div className="post-content">
                  <h5>What's this blog about?</h5>
                  <p>
                    Hi there! My name is Iury Souza and I'm a software engineer mostly focused on mobile development.
                    Originally from Brazil, I've been living in Berlin since 2022.
                    <br/>
                    <br/>
                    Since 2016, I've had the opportunity to work with a variety of organizations, from small software
                    studios
                    to startups and large corporations, and I've loved most of it.
                    <br/>
                    <br/>
                    I'm excited to use this platform to share my
                    experiences and insights with others. I've had the luck to learn a lot through free online
                    resources over the years, and this blog is my way of giving something back to the community.
                    <em> "It's not much, but It's honest work."</em> haha

                    <br/>
                    <br/>
                    I've also been interested in starting writing more for a while now, and this blog will serve s a way
                    for me to learn that skill, so don't expect great writing either, I'm still bad at it.
                    <br/>
                    <br/>

                    Anyways, whether you're a beginner or an experienced dev, I hope you'll find
                    something useful here.
                    So, welcome to the blog! And feel free to reach out to me on my socials.
                    <br/>
                    <br/>
                    In case you want to watch one of my talks you can find them <Link to="/speaking">here</Link>.
                    If you're looking for my dev tools and dev setup, you can find them <Link
                      to="/desksetup">here</Link>, and in case you're wondering, I'm this guy:
                    <br/>
                    <br/>
                    <br/>
                    <ProfilePic/>
                  </p>
                </div>
              </PostFullContent>
            </article>
          </div>
        </main>
        <Footer/>
      </Wrapper>
    </IndexLayout>
  );
}

export default About;
